import SelectSearch from 'react-select-search';
import { fuzzySearch } from 'react-select-search';
import InputCollaborator from './InputCollaborator';
import '../../assets/stylesheets/style.css';
import React from "react"

class SelectCollaborators extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.selected_value || [],
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(event) {
    const target = event;
    this.setState({value: target});
  }

  render() {
    return <div>
        <SelectSearch options={this.props.users} value={this.props.selected_value} closeOnSelect={false} printOptions="on-focus" multiple filterOptions={fuzzySearch} placeholder="Select your collaborators" onChange={this.handleSelectChange}/>
        {this.state.value.map((subItems, sIndex) => {
            return <InputCollaborator key={sIndex} value={subItems}/>
          })}
      </div>
  }
}

export default SelectCollaborators
