// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "./modals.js";
import "./sidebar.js";

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'alpine-turbo-drive-adapter'
import flatpickr from "flatpickr";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import LocalTime from 'helpers/local-time'
LocalTime.start()

require("flatpickr/dist/flatpickr.css");
require('jquery')
require('flatpickr/dist/plugins/monthSelect/style.css')
require('../../assets/stylesheets/style.css')

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

window.flatpickrInit = function() {
  flatpickr(".datepicker", {
    plugins: [
      new monthSelectPlugin({
        altInput: true,
        dateFormat: "F Y",
        altFormat: "F Y",
      })
    ]
  });
}

flatpickrInit();