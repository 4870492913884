import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Legend, Text } from 'recharts';

const renderActiveShape = (state) => (props) => {
  const { total_emission_reduction } = state;
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, emission_reduction } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>
        {total_emission_reduction.toLocaleString()}
      </text>
      <text x={cx} y={cy} dy={24} textAnchor="middle" fill={fill}>
        MTCO2e
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill="#8884d8"
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill="#8884d8"
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${emission_reduction.toLocaleString()}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const renderCustomizedLegend = ({ payload }) => {
  return (
    <div className="customized-legend" style={{display: "flex", justifyContent: "center"}}>
      {payload.map(entry => {
        const { dataKey, color } = entry;

        const style = {
          marginRight: 1,
          color: "#AAA",
        };

        return (
          <span
            className="legend-item flex mt-2 ml-1 mr-1"
            style={style}
          >
            <div className={"h-3 w-3 self-center"} style={{backgroundColor: color}} />
            <span className={"ml-1 flex-1"}>{dataKey.name}</span>
          </span>
        );
      })}
    </div>
  );
};

export default class EmissionsImpactChart extends PureComponent {
  constructor(props) {
    super(props);
    const { objectives, objective_id } = props;
    const activeIndex = objectives.findIndex(x => x.id === objective_id);
    this.state = {
      activeIndex: activeIndex,
    }
  }

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { objectives: allObjectives, total_emission_reduction, objective_id } = this.props;
    const { activeIndex } = this.state;
    const objectives = allObjectives.filter(o => o.id === objective_id || o.emission_reduction > 0);
    return (
      <div className={"p-6"}>
        <h3 className={"font-bold text-slate-600"}>Cumulative Emissions Reduction Potential (Sector - Total)</h3>
        <ResponsiveContainer width="100%" height={400}>
          <PieChart>
            <Pie
              activeIndex={activeIndex || 0}
              activeShape={renderActiveShape({total_emission_reduction})}
              pieLabel={'Pie Label'}
              data={objectives}
              cx="50%"
              cy="50%"
              innerRadius={120}
              outerRadius={140}
              fill="#dad2e9"
              dataKey="emission_reduction"
              onMouseEnter={this.onPieEnter}
            >
            </Pie>
            <Legend 
              verticalAlign="bottom" 
              height={36} 
              dataKey={"name"} 
              payload={objectives.map((a, i) => ({
                dataKey: a,
                color: i === activeIndex ? '#8884d8' : '#dad2e9',
              }))}
              content={renderCustomizedLegend}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
