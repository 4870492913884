import SelectSearch from 'react-select-search';
import { fuzzySearch } from 'react-select-search';
import '../../assets/stylesheets/style.css';
import React from "react"

class SelectAssignee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.selected_value,
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(event) {
    const target = event;
    this.setState({value: target});
  }

  render() {
    return <div>
        <SelectSearch options={this.props.users} value={this.props.selected_value} search filterOptions={fuzzySearch} emptyMessage="Not found" placeholder="Enter assignee name" onChange={this.handleSelectChange}/>
        <input type="hidden" name="task[assignee_id]" value={this.state.value}/>
      </div>
  }
}

export default SelectAssignee
