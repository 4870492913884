window.collapseSidebar = function() {
  document.querySelector(".sidebar").classList.add("w-12");
  document.querySelector(".sidebar").classList.add("sm:w-24");
  document.querySelector(".sidebar").classList.remove("w-32");
  document.querySelector(".sidebar").classList.remove("sm:w-64");
  document.querySelector(".bottom-part").classList.add("w-12");
  document.querySelector(".bottom-part").classList.add("sm:w-24");
  document.querySelector(".bottom-part").classList.remove("w-32");
  document.querySelector(".bottom-part").classList.remove("sm:w-64");
  $(".home-text").addClass("hidden");
  $(".action-text").addClass("hidden");
  $(".account-text").addClass("hidden");
  $(".reporting-text").addClass("hidden");
  $(".help-text").addClass("hidden");
  $(".logout-text").addClass("hidden");
  $("#submenu-action-plan").addClass("hidden");
  $(".info-text").addClass("hidden");
  $(".info-button").addClass("hidden");
  $(".collapsible-text").addClass("hidden");
  $(".line-spacer").addClass("hidden");
  $(".image-profile").addClass("items-center");
  $(".image-profile").addClass("py-2");
  $(".image-profile").addClass("my-4");
  $(".image-profile").removeClass("mt-4");
  $(".image-icon").removeClass("hidden");
  $(".image-icon").removeClass("sm:w-1/4");
  $(".image-icon").addClass("sm:w-2/4");
  $(".image-profile").removeClass("sm:mt-8");
  $(".help-content").addClass("mb-4");
  $(".account-settings").addClass("mb-4");
  $(".collaps-sidebar").addClass("hidden");
  $(".restore-sidebar").addClass("mt-4");
  $(".restore-sidebar").removeClass("hidden");
  $(".small-logo").removeClass("hidden");
  $(".full-logo").addClass("hidden");
  $(".logo-span").removeClass("sm:ml-5");
}