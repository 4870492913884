import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Label,
  LabelList,
  Legend,
  Surface,
  Symbols
} from "recharts";

const data = [
  { name: "NE Send", completed: 230, failed: 335, inprogress: 453 },
];

const CustomizedLabel = ({x, y, fill, value} ) => {
  return <text
    x={x}
    y={y}

    fontSize='12'
    fontFamily='sans-serif'
    fill={fill}
    textAnchor="start">{value}%</text>
};

const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="10" fontWeight="Bold" />;
};

export default class EmissionsImpactBarChart extends PureComponent {
  handleClick = dataKey => {
  };

  render () {

    const renderCustomizedLegend = ({ payload }) => {
      return (
        <div className="customized-legend">
          {payload.map(entry => {
            const { dataKey, color, unallocated } = entry;

            const style = {
              marginRight: 10,
              color: "#AAA",
            };

            return (
              <span
                className="legend-item flex mt-2"
                onClick={() => this.handleClick(dataKey)}
                style={style}
              >
                <div className={"h-6 w-12"} style={{backgroundColor: dataKey.id == 0 ? "#efefef" : color}} />
                <span className={"ml-2 mr-2 w-1/4"}>{dataKey.emission_reduction.toLocaleString('en-US')} MTCO2e</span>
                <span className={"ml-5 flex-1"}>{unallocated === true ? "Unallocated: Other Supporting Sub-Goals" : dataKey.name}</span>
              </span>
            );
          })}
        </div>
      );
    };


    const { actions: allActions, objectives: allObjectives, total_emission_reduction, objective_id } = this.props;

    const objective = allObjectives.find(o => o.id === objective_id);

    let total_action_emissions = 0;

    const actions = allActions
      .filter(a => !!a.emission_reduction && !a.weight)
      .sort((a, b) => a.emission_reduction - b.emission_reduction)
      .slice(0, 5);

    let actionData = {
      name: "Action Emissions Impact",
    };

    let actionDataAllocated = {
      name: "Total Allocated Emissions Impact",
    }

    if(actions.length === 0) {
      actionData[`unallocated`] = objective.emission_reduction;
    } else {
      actions.forEach((a, i) => {
        actionData[`action_${i}`] = a.emission_reduction;
        total_action_emissions = total_action_emissions + a.emission_reduction
      });
      if(objective.emission_reduction - total_action_emissions > 0) {
        actions.push({ id: 0, name: 'Unallocated: Other Supporting Sub-goals', emission_reduction: objective.emission_reduction - total_action_emissions, weight: null });
        actionData[`action_${actions.length - 1}`] = objective.emission_reduction - total_action_emissions;
      }
      actionDataAllocated[`total_emission_allocated`] = objective.emission_reduction;
    };

    const colors = [
      '#7c6efb',
      '#bb9ad0',
      '#b2a6bf',
      '#c7c4c4',
      '#f0eff0',
      '#99A39B',
    ]

    const allocatedColor = "#d9d2e9ff";

    return (
      <div className={"p-6"}>
        <h3 className={"font-bold text-slate-600"}>Cumulative Emissions Reduction Potential (Objective - Total)</h3>
        <div className={"mt-4"}>
          <h2 className={"font-bold inline text-slate-600 text-2xl"}>{objective.emission_reduction.toLocaleString('en-US')}{" "}</h2>
          <span className={"font-bold text-slate-500 text-sm"}>MTCO2e</span>
        </div>
        <h3 className={"font-bold text-slate-600"}>{objective.name}</h3>
        <ResponsiveContainer width="100%" height={40}>
          <BarChart
            layout="vertical"
            data={[actionDataAllocated]}
            margin={{ left: 0, right: 0 }}
            stackOffset="expand"
          >
            <XAxis hide type="number" />
            <YAxis
              hide
              dataKey="name"
              type="category"
            />
            <Tooltip wrapperStyle={{ top: -50 }} />
            <Bar key={`total_emission_allocated`} dataKey={`total_emission_allocated`} barSize={40} fill={allocatedColor} stackId="a">
              <LabelList
                dataKey={`total_emission_allocated`}
                position="center"
                content={() => {(null /*renderCustomizedLabel*/)}}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            layout="vertical"
            data={[actionData]}
            margin={{ left: 0, right: 0, top: -40 }}
            stackOffset="expand"
          >
            <XAxis hide type="number" />
            <YAxis
              hide
              dataKey="name"
              type="category"
            />
            <Tooltip />
            {
              actions.length === 0 ? 
                <Bar key={`unallocated`} dataKey={`unallocated`} fill={colors[0]} stackId="a">
                  <LabelList
                    dataKey={`unallocated`}
                    position="center"
                    content={() => {(null /*renderCustomizedLabel*/)}}
                  />
                </Bar> :
                actions.map((a, i) => (
                  <Bar key={`action_${i}`} dataKey={`action_${i}`} barSize={10} fill={i == actions.length - 1 ? "#efefef" : colors[i]} stackId="a">
                    <LabelList
                      dataKey={`action_${i}`}
                      position="center"
                      content={() => {(null /*renderCustomizedLabel*/)}}
                    />
                  </Bar>
                ))
            }
            {
              actions.length === 0 ? 
                <Legend
                  verticalAlign="bottom"
                  height={350}
                  align="left"
                  payload={[{
                    dataKey: objective,
                    color: colors[0],
                    unallocated: true,
                  }]}
                  content={renderCustomizedLegend}
                /> :
                <Legend
                  verticalAlign="bottom"
                  height={350}
                  align="left"
                  payload={actions.map((a, i) => ({
                    dataKey: a,
                    color: colors[i],
                    unallocated: false,
                  }))}
                  content={renderCustomizedLegend}
                />
            }
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
