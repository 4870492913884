window.toggleCreateActionModal = function() {
  $(".toggle-create-action-modal").click();
};

window.toggleCreateObjectiveModal = function() {
  $(".toggle-create-objective-modal").click();
};

window.toggleCreateActivityModal = function() {
  $(".toggle-create-activity-modal").click();
};

window.toggleCreateTaskModal = function() {
  $(".toggle-create-task-modal").click();
};

window.toggleUpdateTaskModal = function() {
  $(".toggle-update-task-modal").click();
};

window.toggleUpdateActionModal = function() {
  $(".toggle-update-action-modal").click();
};

window.toggleCreateResourceModal = function() {
  $(".toggle-create-resource-modal").click();
};

window.toggleEditResourceModal = function() {
  $(".toggle-edit-resource-modal").click();
};

window.toggleAddUserModal = function() {
  $(".toggle-add-user-modal").click();
}

window.toggleEditUserModal = function() {
  $(".toggle-edit-user-modal").click();
}