import React from "react"

class InputCollaborator extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <input type="hidden" name="user_id[]" value={this.props.value}/> 
  }
}

export default InputCollaborator
